<template>
    <div>    
        <vx-card class="mt-8">
            <div class="card-title">
                <h2 class="w-auto my-2">{{ LabelConstant.headingLabelFormCapturesConfigured }}</h2>
                <vs-button
                    :title="LabelConstant.buttonLabelAddNewFormCapture"
                    @click="addFormCapture=true, createEditFormCaptureTitle=LabelConstant.popupTitleCreateNewFormCapture"                    
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    class="small-btn mr-3 bg-actionbutton"
                    >{{ LabelConstant.buttonLabelAddNewFormCapture }}
                </vs-button>     
            </div>
            <div class="common-table">      
                <!--Table Select Row-->
                <div class="table-row-select">
                    <vs-select :label="LabelConstant.inputLabelShowEntries" v-model="perPage" class="show-select" autocomplete>
                        <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item,index) in recordList"
                        />
                    </vs-select>
                </div>
                <vs-table ref="tableDataFormCapture" :data="formCaptureList" search :max-items="perPage" pagination>
                    <template slot="thead">                
                        <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                        <vs-th sort-key="Form_Name">{{ LabelConstant.tableHeaderLabelFormName }}</vs-th>
                        <vs-th sort-key="Form_Id_External">{{ LabelConstant.tableHeaderLabelFormId }}</vs-th>
                        <vs-th sort-key="Is_Active">{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                        <vs-th sort-key="sortableDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td width="100">
                                <div class="table-action">                            
                                    <vs-button :title="LabelConstant.buttonTitleEdit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left" color="primary" @click="getIndividualFormCreated(tr.Id)"></vs-button>
                                    <vs-button :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"
                                        @click="deleteFormCapturePopupActive=true, deletableFormCaptureName=tr.Form_Name, deletableFormCaptureId = tr.Id" 
                                    ></vs-button> 
                                </div>
                            </vs-td>
                            <vs-td :data="tr.Form_Name">{{tr.Form_Name}}</vs-td>
                            <vs-td :data="tr.Form_Id_External">{{tr.Form_Id_External}}</vs-td>
                            <vs-td :data="tr.Is_Active">
                                <vs-chip color="success" v-if="tr.Is_Active">{{ LabelConstant.statusConditionLabelActive }}</vs-chip>
                                <vs-chip color="danger" v-if="!tr.Is_Active">{{ LabelConstant.statusConditionLabelInActive }}</vs-chip>
                            </vs-td>
                            <vs-td :data="tr.sortableDate">{{tr.Date_Created}}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">
                    Showing {{showingDataFrom}} to {{showingDataTo}} of {{formCaptureList.length|tableRecordNumberFormatter}} entries
                </p>
                <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">
                    Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{formCaptureList.length|tableRecordNumberFormatter}} entries)
                </p>
                <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>                
            </div>                
        </vx-card>    
        <!-- Delete Form Capture Configuration Popup -->
        <vs-popup class="delete-popup" :active.sync="deleteFormCapturePopupActive"> 
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full">
                        <div class="popup-icon">
                            <feather-icon icon="Trash2Icon"></feather-icon>
                        </div>
                        <h4>{{ LabelConstant.popupHeadingLabelDeleteFormCaptureConfiguration }}</h4>
                        <span class="deleted-no">{{deletableFormCaptureName}}</span>                    
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">            
                <vs-button color="danger" type="filled" @click="deleteFormCapture()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click="deleteFormCapturePopupActive=false, deletableFormCaptureName=null, deletableFormCaptureId=null"  color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
        <!-- Form Capture Configuration Popup -->
        <vs-popup class="company-details-modal edit-form" :title="createEditFormCaptureTitle" :active.sync="addFormCapture"> 
            <vs-tabs direction="vertical" :value="activeTab">
                <vs-tab :label="LabelConstant.tabLabelFormInformation" @click="activeTab = 0">
                    <div class="tab-text">
                        <div class="tab-text">
                            <form data-vv-scope="wizardFormOne">
                                <template>
                                    <div class="vx-row">
                                        <div class="vx-col md:w-1/2 w-full mb-6">
                                            <vs-input class="w-full" :label="LabelConstant.inputLabelFormName" v-model="formName" name="formName" v-validate="'required'"/>
                                            <span class="text-danger text-sm">{{ errors.first('wizardFormOne.formName') }}</span>
                                        </div>
                                        <div class="vx-col md:w-1/2 w-full mb-6">
                                            <vs-input class="w-full" :label="LabelConstant.inputLabelFormURL" v-model="formUrl" name="formUrl" v-validate="'required|url:require_protocol'"/>
                                            <span class="text-danger text-sm">{{ errors.first('wizardFormOne.formUrl') }}</span>
                                        </div>
                                    </div>
                                    <div class="vx-row mb-6">
                                        <div class="vx-col w-full">
                                            <span class="vs-input--label">{{ LabelConstant.inputLabelNotes }}</span>
                                            <vs-textarea v-model="formNotes" class="mb-0"/>
                                        </div>    
                                    </div>
                                    <div class="vx-row mb-6">
                                        <div class="vx-col w-full">
                                            <vs-checkbox v-model="formActive" class="ml-0">{{ LabelConstant.inputLableActive }}</vs-checkbox>
                                        </div>
                                    </div>
                                </template>
                            </form>
                        </div> 
                    </div>
                </vs-tab>
                <vs-tab :label="LabelConstant.tabLabelSelectForm" @click="activeTab = 1">
                    <div class="tab-text">
                        <h5 class="tab-form-title">{{ LabelConstant.infoLabelSelectForm }}</h5>
                        <template>
                            <form data-vv-scope="wizardFormTwo">
                                <p class="mb-4">{{ LabelConstant.inputLabelPleaseSelectTheFormYouWouldLikeToCapture }}:</p>
                                <div class="source-list-table form-table mb-6">
                                    <div class="source-list-head">
                                        <div class="filed-head">{{ LabelConstant.inputLabelSelect }}</div>
                                        <div class="filed-head">{{ LabelConstant.inputLabelFormName }}</div>
                                        <div class="filed-head">{{ LabelConstant.inputLabelFormId }}</div>
                                    </div>
                                    <div class="source-list-body">
                                        <div class="list-row" v-for="(selectFrom, index) in wizardFormStepTwoRadioOptions" :key="index">
                                            <div class="filed-data"><vs-radio v-model="selectForm" :vs-value="selectFrom.str"  name="selectForm" v-validate="'required'"></vs-radio></div>
                                            <div class="filed-data"> <span>	{{selectFrom.obj.Key}} </span></div>
                                            <div class="filed-data"> <span>	{{selectFrom.obj.Value}} </span></div>
                                        </div>
                                        <!-- <div class="list-row">
                                            <div class="filed-data"><vs-radio v-model="form" vs-value="form"></vs-radio></div>
                                            <div class="filed-data"> <span>	form</span></div>
                                            <div class="filed-data"> <span>	contactForm</span></div>
                                        </div> -->
                                    </div>
                                </div>
                                <span class="text-danger text-sm">{{ errors.first('wizardFormTwo.selectForm') }}</span>
                            </form>
                        </template>                    
                    </div>
                </vs-tab>
                <vs-tab :label="LabelConstant.tabLabelFormFieldMapping" @click="activeTab = 2">
                    <div class="tab-text mb-6">
                        <h5 class="tab-form-title">{{ LabelConstant.headingLabelMapYourFormFieldsBelow }}:</h5>
                        <div class="source-list-table">
                            <div class="source-list-head">
                                <div class="filed-head">{{ LabelConstant.tabelHeaderLabelSourceYourForm }}</div>
                                <div class="filed-head w-1/3">{{ LabelConstant.tabelHeaderLabelDestination }}</div>
                            </div>
                            <div class="source-list-body">
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.companyName" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelCompanyName }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.domainName" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelDomainName }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.salutation" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelSalutation }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.firstName" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelFirstName }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.lastName" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelLastName }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.title" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelTitle }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.email" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelEmail }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.address1" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelAddress1 }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.address2" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelAddress2 }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.city" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelCity }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.state" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelState }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.zip" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelZip }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.phone" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelPhone }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.phoneSecondary" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelPhoneSecondary }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.industry" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelIndustry }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.revenue" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelRevenue }}</span>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <div class="filed-data">
                                        <v-select v-model="formFieldMapping.notes" :options="wizardFormStepThreeDropdownOptions"/>
                                    </div>
                                    <div class="filed-data w-1/3">
                                        <span>{{ LabelConstant.inputLabelNotes }}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <span class="text-danger text-sm" v-if="formFieldMappingErrorMessage">{{ formFieldMappingErrorMessage }}</span>
                    </div>
                </vs-tab>
                <vs-tab label="Installation Code" @click="activeTab = 3">
                    <div class="tab-text">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <h5 class="mb-4 tab-form-title">{{ LabelConstant.inputLabelInsertJavascriptCode }}</h5>
                                <vs-textarea v-model="installationCode" class="mb-6  form-text-area"/> 
                            </div>
                        </div>
                    </div>
                </vs-tab>
            </vs-tabs>
            <div class="flex justify-end w-full mt-6 flex-wrap mb-8">            
                <vs-button color="primary" v-if="activeTab == 0 && !formCaptureEditMode" type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0  w-full sm:w-auto" @click="updateFormWizardStep1()" :disabled="isTabOneEnabled">{{ LabelConstant.buttonLabelNext }}</vs-button>
                <vs-button color="primary" v-if="activeTab == 1 && !formCaptureEditMode" type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0  w-full sm:w-auto" @click="updateFormWizardStep2()" :disabled="isTabTwoEnabled">{{ LabelConstant.buttonLabelNext }}</vs-button>
                <vs-button color="primary" v-if="activeTab == 2 && !formCaptureEditMode" type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0  w-full sm:w-auto" @click="updateFormWizardStep3()" :disabled="isTabThreeEnabled">{{ LabelConstant.buttonLabelSaveFormCapture }}</vs-button>

                <vs-button color="primary" v-if="activeTab == 0 && formCaptureEditMode" type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0  w-full sm:w-auto" @click="updateFormWizardStep1Edit()" :disabled="isTabOneEnabled">{{ LabelConstant.buttonLabelNext }}</vs-button>
                <vs-button color="primary" v-if="activeTab == 1 && formCaptureEditMode" type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0  w-full sm:w-auto" @click="updateFormWizardStep2Edit()" :disabled="isTabTwoEnabled">{{ LabelConstant.buttonLabelNext }}</vs-button>
                <vs-button color="primary" v-if="activeTab == 2 && formCaptureEditMode" type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0  w-full sm:w-auto" @click="updateFormWizardStep3Edit()" :disabled="isTabThreeEnabled">{{ LabelConstant.buttonLabelSaveFormCapture }}</vs-button>
                
                <vs-button v-if="activeTab == 0 || activeTab == 1" @click="cancelFormCapture()" color="grey" type="border" class="w-full sm:w-auto mb-2 sm:mb-0 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
                <vs-button v-if="activeTab == 2" @click="cancelFormCapture()" color="grey" type="border" class="w-full sm:w-auto mb-2 sm:mb-0 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
                <vs-button v-if="activeTab == 3" @click="cancelFormCapture()" color="grey" type="border" class="w-full sm:w-auto mb-2 sm:mb-0 grey-btn" >{{ LabelConstant.buttonLabelClose }}</vs-button>
            </div>            
        </vs-popup>
        <!-- Help popup -->
        <help-modal :popupActive='helpPopupActive' @close="closepopup" ></help-modal>
    </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import DateRangePicker from "../../components/filter/DateRangePicker";
import vSelect from 'vue-select'
import HelpModal from "../../components/modal/HelpModal";

import { Validator } from 'vee-validate';
const dict = {
    custom: {
        formName: {
            required: 'Form name must be more than 1 and less than 300 characters'
        },
        formUrl: {
            required: 'A Form URL is required',
            url: 'Please enter a valid url.'
        },
        selectForm: {
            required: 'Please select an available form to capture.'
        }
    }
}
Validator.localize('en', dict);

export default {
    components: {
        VxCard,
        DateRangePicker, 
        vSelect,
        HelpModal
    },
    data() {
        return {
            helpPopupActive: false,
           
            formCaptureList: [],

            createEditFormCaptureTitle: null,

            addFormCapture:false,
            activeTab: 0,
            tabOne: false,
            tabTwo: true,
            tabThree: true,
            tabFour: true,

            formName: null,
            formUrl: null,
            formNotes: null,
            formActive: true,
            selectForm: null,
            formFieldMapping: {
                companyName: null,
                domainName: null,
                salutation: null,
                firstName: null,
                lastName: null,
                title: null,
                email: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip: null,
                phone: null,
                phoneSecondary: null,
                industry: null,
                revenue: null,
                notes: null
            },
            formFieldMappingErrorMessage: null,

            wizardFormStepTwoRadioOptions: [],
            wizardFormStepThreeDropdownOptions: [],

            installationCode: null,

            deleteFormCapturePopupActive:false,
            deletableFormCaptureName: null,
            deletableFormCaptureId: null,

            formCaptureEditMode: false,
            formCaptureEditData: null,
            formCaptureEditId: null,

            perPage: 5,
            recordList: [
                {text: '5', value: 5},
                {text: '10', value: 10},
                {text: '25', value: 25},
                {text: '50', value: 50},
                {text: '100', value: 100}
            ],
        };
    },
    created(){
        this.getFormsCreatedList();
    },
    methods: {
        /* Close Help Popup */
        closepopup() {
            (this.helpPopupActive = false)
        },
        getFormsCreatedList(){
            this.$vs.loading();
            this.axios.get("/ws/ConfigFormCapture/GetFormsCreatedList").then(response => {
                let data = response.data;
                this.formCaptureList = data
                this.formCaptureList.sort(this.GetSortOrder("Form_Name", "ASC")); //Pass the attribute to be sorted on
                this.formCaptureList.map(function(manipulatedData) {
                    manipulatedData.sortableDate = new Date(manipulatedData.Date_Created);
                    return manipulatedData;
                });
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        cancelFormCapture(){
            this.createEditFormCaptureTitle = null
            this.addFormCapture = false
            this.activeTab = 0
            this.tabOne = false
            this.tabTwo = true
            this.tabThree = true
            this.tabFour = true

            this.formCaptureEditMode = false
            this.formCaptureEditData = null
            this.formCaptureEditId = null

            this.formName = null
            this.formUrl = null
            this.formNotes = null
            this.formActive = true
            this.selectForm = null
            this.formFieldMapping = {
                companyName: null,
                domainName: null,
                salutation: null,
                firstName: null,
                lastName: null,
                title: null,
                email: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip: null,
                phone: null,
                phoneSecondary: null,
                industry: null,
                revenue: null,
                notes: null
            }
            this.formFieldMappingErrorMessage = null

            this.wizardFormStepTwoRadioOptions = []
            this.wizardFormStepThreeDropdownOptions = []

            this.installationCode = null

            setTimeout(() => { 
                this.errors.clear('wizardFormOne')
                this.errors.clear('wizardFormTwo')
            }, 20);

            this.getFormsCreatedList()
        },
        updateFormWizardStep1(){
            this.$validator.validateAll('wizardFormOne').then(result => {
                if (result) {
                    let updateFormWizardStep1Params = {
                        Form_Name: this.formName,
                        Form_URL: this.formUrl,
                        Notes: this.formNotes,
                        Is_Active: this.formActive
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/ConfigFormCapture/UpdateFormWizardStep1",updateFormWizardStep1Params).then(response => {
                        let data = response.data
                        // this.wizardFormStepTwoRadioOptions = data.Form_Name_And_Id
                        for (let i = 0; i < data.Form_Name_And_Id.length; i++) {
                            let wizardFormStepTwoRadioOptionsString = JSON.stringify({Key:data.Form_Name_And_Id[i].Key, Value:data.Form_Name_And_Id[i].Value})
                            this.wizardFormStepTwoRadioOptions.push({
                                str:wizardFormStepTwoRadioOptionsString,
                                obj:data.Form_Name_And_Id[i]
                            })
                        }
                        // this.tabTwo = false
                        this.activeTab = 1
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        updateFormWizardStep2(){
            this.$validator.validateAll('wizardFormTwo').then(result => {
                if (result) {
                    let selectFormObj = JSON.parse(this.selectForm)
                    let updateFormWizardStep2Params = {
                        Form_Name: this.formName,
                        Form_URL: this.formUrl,
                        Notes: this.formNotes,
                        Is_Active: this.formActive,
                        Form_Name_External: selectFormObj.Key,
                        Form_Id_External: selectFormObj.Value
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/ConfigFormCapture/UpdateFormWizardStep2",updateFormWizardStep2Params).then(response => {
                        let data = response.data
                        this.wizardFormStepThreeDropdownOptions = data.Form_Fields
                        // for (let i = 0; i < data.Form_Fields.length; i++) {
                        //     this.wizardFormStepThreeDropdownOptions.push({
                        //         id: i, 
                        //         label: data.Form_Fields[i]
                        //     })
                        // }
                        // this.tabTwo = false
                        this.activeTab = 2
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        updateFormWizardStep3(){
            let fieldMapping = this.formFieldMapping
            if(fieldMapping.companyName == null && fieldMapping.domainName == null && fieldMapping.salutation == null && fieldMapping.firstName == null && fieldMapping.lastName == null 
            && fieldMapping.title == null && fieldMapping.email == null && fieldMapping.address1 == null && fieldMapping.address2 == null && fieldMapping.city == null && fieldMapping.state == null 
            && fieldMapping.zip == null && fieldMapping.phone == null && fieldMapping.phoneSecondary == null && fieldMapping.industry == null && fieldMapping.revenue == null && fieldMapping.notes == null){
                this.formFieldMappingErrorMessage = 'Please map at least one field.'
                return false
            }
            let fieldMappingFinal = []
            if(fieldMapping.companyName){
                fieldMappingFinal.push({
                    Key: 'Company_Name',
                    Value: fieldMapping.companyName
                })
            }
            if(fieldMapping.domainName){
                fieldMappingFinal.push({
                    Key: 'Domain_Name',
                    Value: fieldMapping.domainName
                })
            }
            if(fieldMapping.salutation){
                fieldMappingFinal.push({
                    Key: 'Salutation',
                    Value: fieldMapping.salutation
                })
            }
            if(fieldMapping.firstName){
                fieldMappingFinal.push({
                    Key: 'First_Name',
                    Value: fieldMapping.firstName
                })
            }
            if(fieldMapping.lastName){
                fieldMappingFinal.push({
                    Key: 'Last_Name',
                    Value: fieldMapping.lastName
                })
            }
            if(fieldMapping.title){
                fieldMappingFinal.push({
                    Key: 'Title',
                    Value: fieldMapping.title
                })
            }
            if(fieldMapping.email){
                fieldMappingFinal.push({
                    Key: 'Email',
                    Value: fieldMapping.email
                })
            }
            if(fieldMapping.address1){
                fieldMappingFinal.push({
                    Key: 'Address1',
                    Value: fieldMapping.address1
                })
            }
            if(fieldMapping.address2){
                fieldMappingFinal.push({
                    Key: 'Address2',
                    Value: fieldMapping.address2
                })
            }
            if(fieldMapping.city){
                fieldMappingFinal.push({
                    Key: 'City',
                    Value: fieldMapping.city
                })
            }
            if(fieldMapping.state){
                fieldMappingFinal.push({
                    Key: 'State',
                    Value: fieldMapping.state
                })
            }
            if(fieldMapping.zip){
                fieldMappingFinal.push({
                    Key: 'Zip',
                    Value: fieldMapping.zip
                })
            }
            if(fieldMapping.phone){
                fieldMappingFinal.push({
                    Key: 'Phone_Primary',
                    Value: fieldMapping.phone
                })
            }
            if(fieldMapping.phoneSecondary){
                fieldMappingFinal.push({
                    Key: 'Phone_Secondary',
                    Value: fieldMapping.phoneSecondary
                })
            }
            if(fieldMapping.industry){
                fieldMappingFinal.push({
                    Key: 'Industry',
                    Value: fieldMapping.industry
                })
            }
            if(fieldMapping.revenue){
                fieldMappingFinal.push({
                    Key: 'Revenue',
                    Value: fieldMapping.revenue
                })
            }
            if(fieldMapping.notes){
                fieldMappingFinal.push({
                    Key: 'Notes',
                    Value: fieldMapping.notes
                })
            }
            let selectFormObj = JSON.parse(this.selectForm)
            let updateFormWizardStep3Params = {
                Form_Name: this.formName,
                Form_URL: this.formUrl,
                Notes: this.formNotes,
                Is_Active: this.formActive,
                Form_Name_External: selectFormObj.Key,
                Form_Id_External: selectFormObj.Value,
                FormFieldMap: fieldMappingFinal,
                Field_List: this.wizardFormStepThreeDropdownOptions
            }
            this.$vs.loading()
            this.axios.post("/ws/ConfigFormCapture/UpdateFormWizardStep3",updateFormWizardStep3Params).then(response => {
                let data = response.data
                this.installationCode = data.Installation_Code
                // this.tabTwo = false
                this.activeTab = 3
                this.$vs.notify({
                    title:'Success',
                    text: 'Form Capture save successful.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });
        },
        deleteFormCapture(){
            this.$vs.loading()
            this.axios.get("/ws/ConfigFormCapture/DeleteFormCapture?id="+this.deletableFormCaptureId).then(() => {
                this.$refs.tableDataFormCapture.searchx = null
                this.getFormsCreatedList()
                this.deleteFormCapturePopupActive = false
                this.deletableFormCaptureName = null
                this.deletableFormCaptureId = null
                this.$vs.notify({
                    title:'Success',
                    text: 'Form Capture delete successful.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });
        },
        getIndividualFormCreated(id){
            this.$vs.loading();
            this.axios.get("/ws/ConfigFormCapture/GetIndividualFormCreated?id=" + id).then(response => {
                let data = response.data
                this.createEditFormCaptureTitle = this.LabelConstant.popupTitleEditFormCaptureConfiguration;
                this.formCaptureEditData = data
                this.formCaptureEditMode = true
                this.formCaptureEditId = data.Id
                
                this.formName = data.Form_Name
                this.formUrl = data.Form_URL
                this.formNotes = data.Notes
                this.formActive = data.Is_Active

                this.selectForm = JSON.stringify({Key:data.Form_Name_External, Value:data.Form_Id_External})
                let wizardFormStepTwoRadioOptionsString = JSON.stringify({Key:data.Form_Name_External, Value:data.Form_Id_External})
                this.wizardFormStepTwoRadioOptions = [{str:wizardFormStepTwoRadioOptionsString,obj:{Key:data.Form_Name_External, Value:data.Form_Id_External}}]

                this.wizardFormStepThreeDropdownOptions = data.Field_List

                for (let i = 0; i < data.Field_Map.length; i++) {
                    if(data.Field_Map[i].Internal_Field == 'Company_Name'){
                        this.formFieldMapping.companyName = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Domain_Name'){
                        this.formFieldMapping.domainName = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Salutation'){
                        this.formFieldMapping.salutation = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'First_Name'){
                        this.formFieldMapping.firstName = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Last_Name'){
                        this.formFieldMapping.lastName = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Title'){
                        this.formFieldMapping.title = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Email'){
                        this.formFieldMapping.email = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Address1'){
                        this.formFieldMapping.address1 = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Address2'){
                        this.formFieldMapping.address2 = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'City'){
                        this.formFieldMapping.city = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'State'){
                        this.formFieldMapping.state = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Zip'){
                        this.formFieldMapping.zip = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Phone_Primary'){
                        this.formFieldMapping.phone = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Phone_Secondary'){
                        this.formFieldMapping.phoneSecondary = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Industry'){
                        this.formFieldMapping.industry = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Revenue'){
                        this.formFieldMapping.revenue = data.Field_Map[i].Form_Field
                    }
                    if(data.Field_Map[i].Internal_Field == 'Notes'){
                        this.formFieldMapping.notes = data.Field_Map[i].Form_Field
                    }
                }
                this.installationCode = data.Installation_Code
                this.addFormCapture = true
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        updateFormWizardStep1Edit(){
            if(this.formUrl == this.formCaptureEditData.Form_URL){
                this.activeTab = 1    
            }else{
                this.$validator.validateAll('wizardFormOne').then(result => {
                    if (result) {
                        let selectFormObj = JSON.parse(this.selectForm)
                        let updateFormWizardStep1Params = {
                            Id: this.formCaptureEditData.Id,
                            Form_Name: this.formName,
                            Form_URL: this.formUrl,
                            Notes: this.formNotes,
                            Is_Active: this.formActive,
                            Form_Name_External: selectFormObj.Key,
                            Form_Id_External: selectFormObj.Value,
                            Field_List: {},
                            install_code: ""
                        }
                        this.$vs.loading()
                        this.axios.post("/ws/ConfigFormCapture/UpdateFormWizardStep1",updateFormWizardStep1Params).then(response => {
                            let data = response.data
                            this.selectForm = null
                            this.formFieldMapping = {
                                companyName: null,
                                domainName: null,
                                salutation: null,
                                firstName: null,
                                lastName: null,
                                title: null,
                                email: null,
                                address1: null,
                                address2: null,
                                city: null,
                                state: null,
                                zip: null,
                                phone: null,
                                phoneSecondary: null,
                                industry: null,
                                revenue: null,
                                notes: null
                            }
                            this.wizardFormStepTwoRadioOptions = []
                            for (let i = 0; i < data.Form_Name_And_Id.length; i++) {
                                let wizardFormStepTwoRadioOptionsString = JSON.stringify({Key:data.Form_Name_And_Id[i].Key, Value:data.Form_Name_And_Id[i].Value})
                                this.wizardFormStepTwoRadioOptions.push({
                                    str:wizardFormStepTwoRadioOptionsString,
                                    obj:data.Form_Name_And_Id[i]
                                })
                            }
                            // this.tabTwo = false
                            this.activeTab = 1
                            this.$vs.loading.close()
                        }).catch(e => {
                            this.$vs.loading.close()
                            this.showError(e);
                            this.$vs.notify({
                                title:'Error',
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: 'feather',
                                icon:'icon-alert-circle',
                                position:"top-right",
                                time:4000
                            });
                        });
                    }
                })
            }
        },
        updateFormWizardStep2Edit(){
            if(this.formUrl == this.formCaptureEditData.Form_URL){
                this.activeTab = 2
            }else{
                this.$validator.validateAll('wizardFormTwo').then(result => {
                    if (result) {
                        let selectFormObj = JSON.parse(this.selectForm)
                        let updateFormWizardStep2Params = {
                            Id: this.formCaptureEditData.Id,
                            Form_Name: this.formName,
                            Form_URL: this.formUrl,
                            Notes: this.formNotes,
                            Is_Active: this.formActive,
                            Form_Name_External: selectFormObj.Key,
                            Form_Id_External: selectFormObj.Value,
                            Field_List: {},
                            install_code: ""
                        }
                        this.$vs.loading()
                        this.axios.post("/ws/ConfigFormCapture/UpdateFormWizardStep2",updateFormWizardStep2Params).then(response => {
                            let data = response.data
                            this.wizardFormStepThreeDropdownOptions = data.Form_Fields
                            // for (let i = 0; i < data.Form_Fields.length; i++) {
                            //     this.wizardFormStepThreeDropdownOptions.push({
                            //         id: i, 
                            //         label: data.Form_Fields[i]
                            //     })
                            // }
                            // this.tabTwo = false
                            this.activeTab = 2
                            this.$vs.loading.close()
                        }).catch(e => {
                            this.$vs.loading.close()
                            this.showError(e);
                            this.$vs.notify({
                                title:'Error',
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: 'feather',
                                icon:'icon-alert-circle',
                                position:"top-right",
                                time:4000
                            });
                        });
                    }
                })
            }
        },
        updateFormWizardStep3Edit(){
            let fieldMapping = this.formFieldMapping
            if(fieldMapping.companyName == null && fieldMapping.domainName == null && fieldMapping.salutation == null && fieldMapping.firstName == null && fieldMapping.lastName == null 
            && fieldMapping.title == null && fieldMapping.email == null && fieldMapping.address1 == null && fieldMapping.address2 == null && fieldMapping.city == null && fieldMapping.state == null 
            && fieldMapping.zip == null && fieldMapping.phone == null && fieldMapping.phoneSecondary == null && fieldMapping.industry == null && fieldMapping.revenue == null && fieldMapping.notes == null){
                this.formFieldMappingErrorMessage = 'Please map at least one field.'
                return false
            }
            let fieldMappingFinal = []
            if(fieldMapping.companyName){
                fieldMappingFinal.push({
                    Key: 'Company_Name',
                    Value: fieldMapping.companyName
                })
            }
            if(fieldMapping.domainName){
                fieldMappingFinal.push({
                    Key: 'Domain_Name',
                    Value: fieldMapping.domainName
                })
            }
            if(fieldMapping.salutation){
                fieldMappingFinal.push({
                    Key: 'Salutation',
                    Value: fieldMapping.salutation
                })
            }
            if(fieldMapping.firstName){
                fieldMappingFinal.push({
                    Key: 'First_Name',
                    Value: fieldMapping.firstName
                })
            }
            if(fieldMapping.lastName){
                fieldMappingFinal.push({
                    Key: 'Last_Name',
                    Value: fieldMapping.lastName
                })
            }
            if(fieldMapping.title){
                fieldMappingFinal.push({
                    Key: 'Title',
                    Value: fieldMapping.title
                })
            }
            if(fieldMapping.email){
                fieldMappingFinal.push({
                    Key: 'Email',
                    Value: fieldMapping.email
                })
            }
            if(fieldMapping.address1){
                fieldMappingFinal.push({
                    Key: 'Address1',
                    Value: fieldMapping.address1
                })
            }
            if(fieldMapping.address2){
                fieldMappingFinal.push({
                    Key: 'Address2',
                    Value: fieldMapping.address2
                })
            }
            if(fieldMapping.city){
                fieldMappingFinal.push({
                    Key: 'City',
                    Value: fieldMapping.city
                })
            }
            if(fieldMapping.state){
                fieldMappingFinal.push({
                    Key: 'State',
                    Value: fieldMapping.state
                })
            }
            if(fieldMapping.zip){
                fieldMappingFinal.push({
                    Key: 'Zip',
                    Value: fieldMapping.zip
                })
            }
            if(fieldMapping.phone){
                fieldMappingFinal.push({
                    Key: 'Phone_Primary',
                    Value: fieldMapping.phone
                })
            }
            if(fieldMapping.phoneSecondary){
                fieldMappingFinal.push({
                    Key: 'Phone_Secondary',
                    Value: fieldMapping.phoneSecondary
                })
            }
            if(fieldMapping.industry){
                fieldMappingFinal.push({
                    Key: 'Industry',
                    Value: fieldMapping.industry
                })
            }
            if(fieldMapping.revenue){
                fieldMappingFinal.push({
                    Key: 'Revenue',
                    Value: fieldMapping.revenue
                })
            }
            if(fieldMapping.notes){
                fieldMappingFinal.push({
                    Key: 'Notes',
                    Value: fieldMapping.notes
                })
            }
            let selectFormObj = JSON.parse(this.selectForm)
            let updateFormWizardStep3Params = {
                Id: this.formCaptureEditData.Id,
                Form_Name: this.formName,
                Form_URL: this.formUrl,
                Notes: this.formNotes,
                Is_Active: this.formActive,
                Form_Name_External: selectFormObj.Key,
                Form_Id_External: selectFormObj.Value,
                FormFieldMap: fieldMappingFinal,
                Field_List: this.wizardFormStepThreeDropdownOptions,
                install_code: ""
            }
            this.$vs.loading()
            this.axios.post("/ws/ConfigFormCapture/UpdateFormWizardStep3",updateFormWizardStep3Params).then(response => {
                let data = response.data
                this.installationCode = data.Installation_Code
                // this.tabTwo = false
                this.activeTab = 3
                this.$vs.notify({
                    title:'Success',
                    text: 'Form Capture save successful.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });
        }
    },
    computed: {
        isTabOneEnabled: function () {
            if(!this.formName || !this.formUrl){
                return true
            }else{
                return false
            }
        },
        isTabTwoEnabled: function () {
            if(!this.selectForm){
                return true
            }else{
                return false
            }
        },
        isTabThreeEnabled: function () {
            if(this.formFieldMapping.companyName == null && this.formFieldMapping.domainName == null && this.formFieldMapping.salutation == null && this.formFieldMapping.firstName == null && this.formFieldMapping.lastName == null && this.formFieldMapping.title == null && this.formFieldMapping.email == null && this.formFieldMapping.address1 == null && this.formFieldMapping.address2 == null && this.formFieldMapping.city == null && this.formFieldMapping.state == null && this.formFieldMapping.zip == null && this.formFieldMapping.phone == null && this.formFieldMapping.phoneSecondary == null && this.formFieldMapping.industry == null && this.formFieldMapping.revenue == null && this.formFieldMapping.notes == null){
                return true
            }else{
                return false
            }
        },

        isTableRecordEntriesDisplay(){
			if(this.formCaptureList.length > 0){
				if(this.$refs.tableDataFormCapture.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
        isSearch: function () {
            if(this.formCaptureList.length > 0){
                if(this.$refs.tableDataFormCapture.searchx == null || this.$refs.tableDataFormCapture.searchx == ""){
                    return false
                }else{
                    return true
                }
            }
        },
        showingDataFrom: function () {
            if(this.formCaptureList.length > 0){
                return ((this.$refs.tableDataFormCapture.currentx - 1) * this.perPage) + 1 
            }
        },
        showingDataTo: function () {
            if(this.formCaptureList.length > 0){
                let totalPages = this.$refs.tableDataFormCapture.getTotalPages
                if(totalPages == this.$refs.tableDataFormCapture.currentx){
                    return ((this.$refs.tableDataFormCapture.currentx - 1) * this.perPage) + this.$refs.tableDataFormCapture.datax.length
                }else{
                    return ((this.$refs.tableDataFormCapture.currentx) * this.perPage)
                }
            }
        },
        totalSearchedData: function () {
            if(this.formCaptureList.length > 0){
                return this.$refs.tableDataFormCapture.queriedResults.length
            }
        },
        showingSearchedDataTo: function () {
            if(this.formCaptureList.length > 0){
                let totalPages = this.$refs.tableDataFormCapture.getTotalPagesSearch
                if(totalPages == this.$refs.tableDataFormCapture.currentx){
                    return ((this.$refs.tableDataFormCapture.currentx - 1) * this.perPage) + this.$refs.tableDataFormCapture.datax.length
                }else{
                    return ((this.$refs.tableDataFormCapture.currentx) * this.perPage)
                }
            }
        }
    },
};
</script>
